
import { Component, Vue } from "vue-property-decorator";

@Component<LoginPage>({
	layout: "sign_in",

	head() {
		return {
			title: "Log in",
		};
	},
})
export default class LoginPage extends Vue {
	phone: string = "";

	updatePhone(phone: string) {
		this.phone = phone;
	}
}
